<!--
 * @Author: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @Date: 2022-06-29 17:25:31
 * @LastEditors: 南方 9528429+nanfangyinan@user.noreply.gitee.com
 * @LastEditTime: 2022-07-25 16:39:16
 * @FilePath: \vue-official-website-of-xiaomi-master\src\components\recruit\Recruit.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="page-main home-main" id="d1">
      <SiteTopbar/>
      <div class="heade-img"></div>
    <div class="containers">

           <div class="cont-title">
          <img src="../../assets/imgs/TKK/tu.png" width="24px" height="24px">
          <p>  基础信息  </p>
          <img src="../../assets/imgs/TKK/tu.png" width="24px" height="24px">
      </div>
      <!-- <div class="content-title">
          <img src="../../assets/imgs/TKK/tuever.png" width="27px" height="27px">
          <p>深圳市小秤砣科技有限公司</p>
      </div> -->
      <div class="contentss">
          <p>图哐哐是由深圳市小秤砣科技有限公司开发。
              <br/>拥有完全自主知识产权，软件界面，核心图标，版面样式和外观均已申请国家专利保护。
              <br/>CAD专业领域3D技术也拥有完全自主研发的3D引擎，图形算法，3D模型识别评分系统，3D推荐系统等多项相关独有技术也已申请国家发明专利。
              <br/>更多专有和创新技术正在研发中，100%掌握自己的核心技术。</p>
      </div>

         <div class="cont-title">
          <img src="../../assets/imgs/TKK/tu.png" width="24px" height="24px">
          <p>  图哐哐释义  </p>
          <img src="../../assets/imgs/TKK/tu.png" width="24px" height="24px">
      </div>
        <div class="contents-img">
          <img src="../../assets/imgs/TKK/191_191.png">
        </div>
       <div class="content-titles">
          <img src="../../assets/imgs/TKK/tuever.png" width="27px" height="27px">
          <p>“图”</p>
      </div>
      <div class="contentss">
          <p>代表工业和制造业及相关行业所流通的各类图纸，图片，图形等图文信息。</p>
      </div>

         <div class="content-titles">
          <img src="../../assets/imgs/TKK/tuever.png" width="27px" height="27px">
          <p>“哐哐”</p>
      </div>
      <div class="contentss">
          <p>代表工业和制造业相关行业的各类机器、设备和工作场景中创造出来的声音哐 哐 哐。</p>
      </div>

            <div class="cont-title">
          <img src="../../assets/imgs/TKK/tu.png" width="24px" height="24px">
          <p>  图哐哐的定位  </p>
          <img src="../../assets/imgs/TKK/tu.png" width="24px" height="24px">
      </div>

       <div class="content-titles">
          <img src="../../assets/imgs/TKK/tuever.png" width="27px" height="27px">
          <p>定位</p>
      </div>
      <div class="contentss">
          <p style="font-weight: 600;">服务制造业的3D互联平台。</p>
          <p>图哐哐专注于中国制造业，以最新的前沿科技技术去服务好千万中小微企业，将为企业、设计师、工程师、管理者等制造业相关方搭建信息流动平台。</p>
      </div>

         <div class="content-titles">
          <img src="../../assets/imgs/TKK/tuever.png" width="27px" height="27px">
          <p>Slogan</p>
      </div>
      <div class="contentss">
          <p style="font-weight: 600;">刷3D，更有深度。</p>
          <p>不只是有视频，图片和文字，我们主张刷3D，让你的时间更有深度。</p>
      </div>
    </div>
        

           <div class="cont-title" style=" margin-top:100px;padding-top:50px;">
          <img src="../../assets/imgs/TKK/tu.png" width="24px" height="24px">
          <p >  主界面  </p>
          <img src="../../assets/imgs/TKK/tu.png" width="24px" height="24px">
      </div>
    <div class="row">
    <div class="row-img">
      <img src="../../assets/imgs/TKK/tutwoo.png" width="234px"  class="row-img-one">
      <img src="../../assets/imgs/TKK/tutwos.png" width="550px" class="row-img-two">
    </div>
    <div class="row-text">
      <div class="row-texts">
        <p style="color:rgba(255, 195, 0, 1);">点赞评论，一键收藏</p>
        <p>社交属性赋能数字化传播</p>
      </div>

      <div class="row-textss">
        <p>展示除图片，视频之外的产品结构，尺寸，装配等</p>
        <p>技术特征，支持点赞，评论，下载，分享功能，</p>
        <p>赋予产品社交属性，数字化传播</p>
      </div>
    </div>
    </div>

        <div class="containers">
                        <div class="cont-title">
          <img src="../../assets/imgs/TKK/tu.png" width="24px" height="24px">
          <p>  下载  </p>
          <img src="../../assets/imgs/TKK/tu.png" width="24px" height="24px">
      </div>
             <div class="content-titles">
          <img src="../../assets/imgs/TKK/tuever.png" width="27px" height="27px">
          <p>应用商城下载</p>
      </div>
      <div class="contentss">
          <p>返回顶部扫码下载</p>
        
      </div>
        </div>
    <Footer/>
    <Info/>
    <Cebian/>
  </div>
</template>

<script>
import SiteTopbar from '@/components/header/SiteTopbar'
import Footer from '@/components/PageMain/Bottom/Footer'
import Info from '@/components/PageMain/Bottom/Info'
import Cebian from '@/components/Cebian';

export default {
  name: "recruit",
  components: {
  SiteTopbar,
    Footer,
    Info,
    Cebian 
  },
   data(){
    return{
      isOverWX: false
    }
  },
  methods: {
   
    brief(){
     
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  },
    created(){
    const html = document.documentElement;
    document.addEventListener('scroll', (e) => {
      let scrolled = html.scrollTop / (html.scrollHeight - html.clientHeight)
      let scrollTop = document.querySelector('.backtop')

      if(scrolled>0.3){
        scrollTop.style.visibility = 'visible'
      }else{
        scrollTop.style.visibility = 'hidden'
      }
    })
  },
  mounted(){
this.brief()
  }
 
};
</script>

<style>
*{
  margin: 0px;
  padding: 0px;
}

.containers {
  width: 70%;
  margin-right: auto;
  margin-left: auto;
  


}

.heade-img{
  width: 100%;
  background-image: url('../../assets/imgs/TKK/she.png');
  background-repeat:no-repeat;
  height: 630px;
  background-size: 100%;
}

 .cont-title{
   margin-top: 50px;
   display: flex;
 
  justify-content: center;
   height: 60px;
   align-items: center;
   
 }
 .cont-title p{
  font-weight: 700;
   color: rgba(69, 77, 89, 1);
   font-size: 35px;

   margin-left: 10px;
   margin-right: 10px;
 }
  .content-titles{
   margin-top: 50px;

   height: 50px;

   background-size: 100%;
   background-position:left;
 }
 .content-titles p{
   position: relative;
   top: -26px;
   left: 10px;
   color: rgba(69, 77, 89, 1);
font-size: 28px;
font-weight: 700;
 }
 .contentss{

padding-top: 40px;
color: rgba(69, 77, 89, 1);
font-size: 20px;
line-height: 45px;
font-weight: 400;
margin-bottom: 10vh;
 }
 .row{

  width: 70%;
  height: 640px;
 
  display: flex;
  justify-content: center;
  padding-top: 100px;
  margin-left: auto;
  margin-right: auto;
 
}
.row-img{
     width: 50%;
    position: relative;
  }
  .row-img-one{
     position: relative;
    left: 150px;
    top: 30px;
  }
    .row-img-two{
     position: relative;
    left: -150px;
    top: 0px;

  opacity: 0;
     transition: 1s;
  }
   .row-img-two:hover{
     opacity: 1;
      transform:scale(1.2, 1.2);
   }

  
  .row-text{
    margin-top: 150px;
    /* margin-right: 100px; */
    text-align: right;
    color:rgba(69, 77, 89, 1);
  
  }
   .row-texts{
      font-size: 35px;
    }
    .row-textss{
      margin-top: 50px;
      font-size: 20px;
    }
.contents-img{
  text-align: center;
  margin-top: 50px;
}

</style>